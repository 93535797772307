import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { VARIANTS as PILL_VARIANTS } from '@common/constants/pills';
import { OFFER_TYPES } from '@common/constants/filters';
import { PRODUCTS_OFFER, PRODUCTS_SALE } from '@common/routes';

import { StyledOfferPillLink, StyledOfferPill } from './OfferPill.styled';

const OFFER_TYPE_VARIANT_MAP = {
  [OFFER_TYPES.SALE]: PILL_VARIANTS.TERTIARY,
  [OFFER_TYPES.OFFER]: PILL_VARIANTS.QUATERNARY,
  [OFFER_TYPES.VIPOFFER]: PILL_VARIANTS.BRAND,
};

const OfferPill = ({ offerType, draggable }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const variant = OFFER_TYPE_VARIANT_MAP[offerType];

  switch (offerType) {
    case OFFER_TYPES.SALE:
      return (
        <StyledOfferPill variant={variant}
offerType={offerType}>
          <Link
            legacyBehavior
            href={{ pathname: PRODUCTS_SALE }}
            as={getRouteUrl(locale, PRODUCTS_SALE)}
            passHref
          >
            <StyledOfferPillLink draggable={draggable}>
              {t('product.badge.sale', 'Sale')}
            </StyledOfferPillLink>
          </Link>
        </StyledOfferPill>
      );
    case OFFER_TYPES.OFFER:
      return (
        <StyledOfferPill variant={variant}
offerType={offerType}>
          <Link
            legacyBehavior
            href={{ pathname: PRODUCTS_OFFER }}
            as={getRouteUrl(locale, PRODUCTS_OFFER)}
            passHref
          >
            <StyledOfferPillLink draggable={draggable}>
              {t('product.badge.offer', 'Angebot')}
            </StyledOfferPillLink>
          </Link>
        </StyledOfferPill>
      );
    case OFFER_TYPES.VIPOFFER:
      return (
        <StyledOfferPill variant={variant}
offerType={offerType}>
          {t('product.badge.vipOffer', 'VIP-Angebot')}
        </StyledOfferPill>
      );
    default:
      return null;
  }
};

OfferPill.propTypes = {
  offerType: PropTypes.oneOf(Object.keys(OFFER_TYPES)),
  draggable: PropTypes.bool,
};

OfferPill.defaultProps = {
  offerType: undefined,
  draggable: undefined,
};

export default OfferPill;
