import styled, { css } from 'styled-components';

import { getThemeColor, getThemeTransition } from '@utils/styled';

// TODO: update hover, focus, active styles with correct colors.
export const ButtonStyles = css`
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  ${({ theme }) => theme.getFluidSpacing('padding', 'equal', 5)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  outline: none;
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadiuses.circle};
  background-color: ${getThemeColor('grey.100')};
  transition: ${getThemeTransition('background-color', 'fast')};
  cursor: pointer;

  svg {
    width: auto;
    height: 100%;
  }

  &:hover {
    background-color: ${getThemeColor('grey.100')};
  }

  &:focus {
    background-color: ${getThemeColor('grey.100')};
  }

  &:active {
    background-color: #f6f6f6;
  }
`;

export const StyledButton = styled.button`
  ${ButtonStyles};
`;

export const StyledButtonLink = styled.a`
  ${ButtonStyles};
`;

export const IconWrapper = styled.span``;
