import styled, { css } from 'styled-components';

import { media } from '@utils/styled';

import { LAYOUT_COLUMN } from '@common/constants/ui';
import Pill from '@common/components/Pill';
import { PillText } from '@common/components/Pill/Pill.styled';
import { OFFER_TYPES } from '@common/constants/filters';

// got to calc(100%) as text-overflow applies to px width only and calc(100%) translates to px value eventually
const OfferPillTextOverflowStyle = css`
  overflow: hidden;
  max-width: calc(100%);
  text-overflow: ${({ offerType }) => (offerType === OFFER_TYPES.VIPOFFER ? 'clip' : 'ellipsis')};
`;

export const StyledOfferPill = styled(Pill)`
  justify-self: flex-start;
  max-width: 100%;
  padding: 0 1.4rem;
  ${({ theme }) =>
    theme.layoutPreference === LAYOUT_COLUMN &&
    css`
      ${media.from480down`
        margin-left: 0;
  `}
    `};

  & ${PillText} {
    ${OfferPillTextOverflowStyle};

    & a {
      ${OfferPillTextOverflowStyle};
    }
  }
`;

export const StyledOfferPillLink = styled.a`
  display: block;
  width: 100%;
  color: white;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
