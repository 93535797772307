import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { OutdatedLabel, Wrapper } from './OutdatedMask.styled';

const OutdatedMask = ({ linkHref, linkHrefAs }) => {
  const { t } = useTranslation();

  return (
    <Link legacyBehavior
href={linkHref}
as={linkHrefAs}
passHref>
      <Wrapper>
        <OutdatedLabel>{t('product.unavailableLabel', 'nicht mehr verfügbar')}</OutdatedLabel>
      </Wrapper>
    </Link>
  );
};

OutdatedMask.propTypes = {
  linkHref: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
    }),
  }).isRequired,
  linkHrefAs: PropTypes.string.isRequired,
};

export default OutdatedMask;
