import styled, { css } from 'styled-components';

import { getThemeTransition, getThemeColor } from '@utils/styled';

import { VARIANTS, SIZES } from '@common/constants/pills';
import Typography from '@common/components/Typography';

const variantToBackgroundColor =
  state =>
  ({ variant }) => {
    switch (variant) {
      case VARIANTS.PRIMARY: {
        const colors = {
          active: getThemeColor('hue.green'),
          hover: getThemeColor('hue.green'),
          normal: getThemeColor('hue.green'),
        };

        return colors[state];
      }
      case VARIANTS.SECONDARY: {
        const colors = {
          active: getThemeColor('grey.20'),
          hover: getThemeColor('grey.20'),
          normal: getThemeColor('grey.20'),
        };

        return colors[state];
      }
      case VARIANTS.TERTIARY: {
        const colors = {
          active: getThemeColor('hue.red'),
          hover: '#f7344a',
          normal: getThemeColor('hue.red'),
        };

        return colors[state];
      }
      case VARIANTS.QUATERNARY: {
        const colors = {
          active: getThemeColor('hue.blue'),
          hover: '#4da6ff',
          normal: getThemeColor('hue.blue'),
        };

        return colors[state];
      }
      case VARIANTS.BRAND: {
        const colors = {
          active: getThemeColor('grey.20'),
          hover: getThemeColor('grey.20'),
          normal: getThemeColor('grey.20'),
        };

        return colors[state];
      }
      case VARIANTS.LIGHT: {
        const colors = {
          active: getThemeColor('grey.100'),
          hover: getThemeColor('grey.100'),
          normal: getThemeColor('bluegrey.90'),
        };

        return colors[state];
      }
      case VARIANTS.SHADOW:
      case VARIANTS.OUTLINE:
      default: {
        const colors = {
          active: getThemeColor('grey.100'),
          hover: getThemeColor('grey.100'),
          normal: getThemeColor('grey.100'),
        };

        return colors[state];
      }
    }
  };

function variantToColor({ variant }) {
  switch (variant) {
    case VARIANTS.PRIMARY:
      return getThemeColor('grey.100');
    case VARIANTS.SECONDARY:
      return getThemeColor('grey.100');
    case VARIANTS.TERTIARY:
      return getThemeColor('grey.100');
    case VARIANTS.QUATERNARY:
      return getThemeColor('grey.100');
    case VARIANTS.SHADOW:
    case VARIANTS.LIGHT:
    case VARIANTS.OUTLINE:
    default:
      return getThemeColor('bluegrey.15');
  }
}

function variantToBorderColor({ variant }) {
  switch (variant) {
    case VARIANTS.PRIMARY:
      return getThemeColor('hue.green');
    case VARIANTS.SECONDARY:
      return getThemeColor('grey.100');
    case VARIANTS.TERTIARY:
      return getThemeColor('hue.red');
    case VARIANTS.QUATERNARY:
      return getThemeColor('hue.blue');
    case VARIANTS.LIGHT:
      return getThemeColor('bluegrey.90');
    case VARIANTS.SHADOW:
      return 'transparent';
    case VARIANTS.OUTLINE:
    default:
      return getThemeColor('bluegrey.70');
  }
}

function variantToShadow({ theme, variant }) {
  switch (variant) {
    case VARIANTS.SHADOW:
      return theme.shadows[0];
    default:
      return 'none';
  }
}

export const StyledPill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  height: min-content;
  box-shadow: ${variantToShadow};
  border: 1px solid ${variantToBorderColor};
  border-radius: ${({ theme }) => theme.borderRadiuses.pill}rem;
  padding: 0 ${({ size }) => (size === SIZES.SMALL ? 0.6 : 1.4)}rem;
  background-color: ${variantToBackgroundColor('normal')};
  color: ${variantToColor};
  transition: ${getThemeTransition('background-color')};

  button&:focus,
  button&:hover,
  a&:focus,
  a&:hover {
    background-color: ${variantToBackgroundColor('hover')};
    text-decoration: none;
    cursor: pointer;
  }

  button&:active,
  a&:active {
    background-color: ${variantToBackgroundColor('active')};
  }
`;

function sizeToFontVariant(size) {
  switch (size) {
    case SIZES.NORMAL:
      return 'body3';
    case SIZES.SMALL:
    default:
      return 'body4';
  }
}

const getAdditionalPillTextStyles = ({ pillVariant }) =>
  pillVariant === VARIANTS.BRAND &&
  css`
    margin: 0;
    background: ${getThemeColor('brand.goldGradient')};
    -webkit-text-fill-color: transparent;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    background-clip: text;
  `;

export const PillText = styled(Typography).attrs(({ size }) => ({
  customAs: 'span',
  variant: sizeToFontVariant(size),
}))`
  margin: 0;
  line-height: ${({ size }) => (size === SIZES.SMALL ? 1.6 : 2.8)}rem;
  white-space: nowrap;
  ${getAdditionalPillTextStyles};
`;
